import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import * as MT from "@mantine/core";

import * as Api from 'app-api';

import React from "react";
import { Avatar, Combobox, Text } from "@mantine/core";

import "./LY_WorkspaceDropDown.css";

 
export interface LY_WorkspaceDropDownProps extends LY.LY_DropDownBaseViewEditProps {
  
  data?: Api.Workspace[]
  onNewWorkspaceClick?: () => void;
  onWorkspaceChange?:(wp: Api.Workspace) => void;
  selectedWorkspace?:Api.Workspace;
  children?: React.ReactNode;
}
export const LY_WorkspaceDropDown = React.forwardRef<HTMLInputElement,LY_WorkspaceDropDownProps>((
    {
      name,
      data,
      value,
      optionsItemRenderer,
      displayProperty='name',
      valueProperty='name',
      placeholder,
      defaultValue,
      inputClassName,
      model,
      onItemClick,
      onValueChange,
      onChange,
      viewRenderer,
      editRenderer,
      style,
      onNewWorkspaceClick,
      selectedWorkspace,
      onWorkspaceChange,
      ...props
    },
    ref
  ) => {

 
      

    function getOptions(combobox?:MT.ComboboxStore|undefined): React.ReactNode{

      console.log('LY_WorkspaceDropDown3 getOptions data', data);

      let options = data?.map((item, index) => {
        var isActive = item.name === selectedWorkspace?.name;

        var activeClassName = isActive
          ? "LY_WorkspaceDropDown_OptionActive"
          : "";
        var key = `ComboboxOptionKey_${valueProperty}_${item?.workspaceId}_${index}`;

        return (
          <Combobox.Option
            key={key}
            value={item.workspaceId?.toString() || ""}
            selected={isActive}
            className={activeClassName}
          >
            <div className="LY_WorkspaceDropDown_Option">
              <Avatar color={item?.color} size="sm" radius="xl">
                {item.tag}
              </Avatar>
              <Text fw="400" c="#000" className="LY_WorkspaceDropDown_WorkspaceName">
                {item.name}
              </Text>
            </div>
          </Combobox.Option>
        );
      });

      return options;
    }

    function optionItemDisplayRenderer(item: any, combobox?: MT.ComboboxStore | undefined) {

      return  <div className="LY_WorkspaceDropDown_Option">
      <Avatar color={item?.color} size="sm" radius="xl">
        {item.tag}
      </Avatar>
      <Text fw="400" c="#000" className="LY_WorkspaceDropDown_WorkspaceName">
        {item.name}
      </Text>
    </div>;
    }

    function getSelectedItemValue(item: any) {
      if (!item) return "";

      let result = item[displayProperty] || "";

      result = LY.ListColumnManager.getCalculatedValue(result, item, result);

      return result;
    }
 
 


    function getFooter(combobox?: MT.ComboboxStore | undefined) {


      //console.log('LY_WorkspaceDropDown getFooter');



      return (
        <div
          onClick={() => {
            onNewWorkspaceClick && onNewWorkspaceClick();
            closeDropdown(combobox)
          }}
          className="LY_WorkspaceDropDown_Footer"
        >
          <Icons.IconPlus width={13} />
          <p>Workspaces</p>
        </div>
      );
    }

    function closeDropdown(combobox?: MT.ComboboxStore | undefined){

      if(combobox?.dropdownOpened)
        combobox?.closeDropdown();

    }

    function getSelectedItem(combobox?: MT.ComboboxStore | undefined) {

      return (
        <div
          className="LY_WorkspaceDropDown_SelectedItem"
          onClick={() => {
            combobox?.dropdownOpened
              ? combobox?.closeDropdown()
              : combobox?.openDropdown();
          }}
         onBlur={() =>closeDropdown(combobox)}
        >
          <div className="LY_WorkspaceDropDown_SelectedItem_Info">
            <Avatar color={selectedWorkspace?.color} radius="xl"
            size={40}
            >
              {selectedWorkspace?.tag}
            </Avatar>
      {/*       {selectedWorkspace?.name} */}
            <div  className="LY_WorkspaceDropDown_WorkspaceName_Selected">
              {selectedWorkspace?.name}
            </div>  
          </div>

          <div className="LY_WorkspaceDropDown_SelectedItem_arrow">        
          {combobox?.dropdownOpened ? (
            <Icons.IconChevronUp width={20} />
          ) : (
            <Icons.IconChevronDown width={20} />
          )}
        </div>
{/*             <div
            //onClick={()=>combobox?.closeDropdown()}
            onClick={() =>closeDropdown(combobox)}
            >
            {props.children}
            </div> */}
         
        </div>
      );
    };

    function render() {

      console.log('LY_WorkspaceDropDown render');




      var result = (
        <LY.LY_DropDown
        
          ref={ref}
          name={name}
          data={data}
          displayProperty={'name'}
          valueProperty={'workspaceId'}
          value={value}
          placeholder={placeholder}
          model={model}
          isAutoCompleteStyle={false}
          footerRenderer={getFooter}
          selectedItemRenderer={getSelectedItem}
         // optionsRenderer={getOptions}
         // optionsItemRenderer={getOptions}
         optionItemDisplayRenderer={optionItemDisplayRenderer}
         onChange={(item, optionProps) => {
 
          console.log('LY_WorkspaceDropDown onChange item',item);

              if (onWorkspaceChange)
                onWorkspaceChange(item);
            }
            }
          //onSearchChange={onSearchChange}
          optionsContainerClassName={
            props.optionsContainerClassName +
            " LY_WorkspaceDropDown_OptionsContainer"
          }
          optionsContainerStyle={{
            ...props.optionsContainerStyle,
            padding: "10px",
          }}

          optionsMaxHeight={props.optionsMaxHeight}
          optionsTitle="Workspaces"

          //onValueChange={onLocalValueChange}
         // selectedItemDisplayValue={selectedWorkspaceDisplayValue}
          searchInputContainerClassName="LY_WorkspaceDropDown_SearchInputContainer"
          searchInputClassName="LY_WorkspaceDropDown_SearchInput"
       //   searchInputRightIcon={<Icons.IconSearch color="#989899" />}

          editContainerClassName="LY_WorkspaceDropDown_EditContainer"
          inputContainerClassName="LY_WorkspaceDropDown_Container"
          hideSearch={false}
           

          {...props}
        />
      );

      return result;
    }

    return render();
  }
);
