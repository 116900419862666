import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';
import * as MT from '@mantine/core';
import * as React from "react";

import { IconAlertTriangle } from '@tabler/icons-react';

export const LY_ListViewTabsDeleteModal: React.FC<LY.LY_ListViewTabsModuleProps> = (props) => {
 
    const context = LY.useListViewTabsContext();
    const { state } = context;

/*     React.useEffect(() => {
        console.log('LY_ListViewTabsDeleteModal useEffect isDeleteModalOpen', state.isDeleteModalOpen);

    }, [state.isDeleteModalOpen]) */

    return (
        <LIB.ConfirmModal
            visible={state.isDeleteModalOpen}
            onCancelClick={() => {
                state.isDeleteModalOpen = false;
                context.forceUpdate();
            }}
            onConfirmClick={context.onDeleteTab}
            title={<div> Confirm Delete: <b>{state.selectedSettingsItem?.name}</b></div>}
            content={
                <div>
                    Are you sure you want to delete the <b>{state.selectedSettingsItem?.name}</b> View?
                    <MT.Alert
                        variant="light"
                        color="red"
                        icon={<IconAlertTriangle />}
                        style={{ marginTop: 10, padding: 8 }}
                    >
                        There is NO Rollback for this action!
                    </MT.Alert>
                </div>
            }
            confirmButtonLabel="Delete"
        />
    );
}
