import React, { ButtonHTMLAttributes, ChangeEvent, ChangeEventHandler, HTMLAttributes, ReactElement } from 'react';

import * as LY from '_LY_Components';

import './LY_StatsGrid.css';
import * as MT from '@mantine/core';
import * as LIB from '_LIB';


import { Group, Paper, SimpleGrid, Text } from '@mantine/core';
import {
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
  IconArrowUpRight,
  IconArrowDownRight,
} from '@tabler/icons-react';
//import classes from './LY_StatsGrid.module.css';

interface LY_StatsGridProps   {
  // ... additional props
  children?: any; // This line allows the component to accept children
  data:any[];
};


export const LY_StatsGrid: React.FC<LY_StatsGridProps>= (props) => { 


  const icons = {
    user: IconUserPlus,
    discount: IconDiscount2,
    receipt: IconReceipt2,
    coin: IconCoin,
  };
  
/*   const data = [
    { title: 'Bid Price', icon: 'coin', value: '0000', diff: 34 },
    { title: 'Quantity', icon: 'coin', value: '0000', diff: -13 },
    { title: 'Price Per Unit', icon: 'discount', value: '00', diff: 18 },
    { title: 'Profit', icon: 'coin', value: '0000', diff: -30 },
  ] as const; */
  
  
  function render(){

    if(!props.data)
      return null;


     const stats = props.data.map((item:any, index:number) => {

     const Icon = icons[item.icon as keyof typeof icons];
 
     var style:React.CSSProperties = {};

     if(item?.showNumColor){

        if(item.value>0){
          style.color = 'green';
        }else if(item.value<0){
          style.color = 'red';
        }
        
     }
     if(item.color)
        style.color = item.color;

    if(item.isMoney)
      item.value = LIB.ConvertHelper.toMoney(item.value);

    if(item.isPct)
      item.value = LIB.ConvertHelper.toPercentage(item.value);
   
      return (
        <Paper key={item.name+index} withBorder p="md" radius="md"  >
          <Group justify="space-between">
            <Text size="xs" c="dimmed" className='LY_StatsTitle'>
              {item.title}
            </Text>
            <Icon className='LY_StatsTitleIcon' size="1.4rem" stroke={1.5} />
          </Group>
  
          <Group align="flex-end" gap="xs" mt={15}>
            <Text className='LY_StatsTitleValue'
            style={style}
            >{item.value}</Text>
          </Group>
 
        </Paper>
      );
    });
    return (
      <div className="LY_StatsGridContainer">
         {stats}
      </div>
    );
  }


  return render();

  }
