import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';
import * as MT from '@mantine/core';
import * as React from "react";

export const LY_ListViewTabsSettingsModal: React.FC<LY.LY_ListViewTabsModuleProps> = (props) => {


    const context = LY.useListViewTabsContext();

    const { state } = context;


    function render() {
 
        var data  =  state.tabs?.filter(x=>!x.isLinkedShared);
        var pinnedTabs = data?.filter(tab => tab.isPinned);

        return <LY.LY_Modal


            opened={state.isAllTabsSettingsOpen}
            onClose={() => {

                state.isAllTabsSettingsOpen = false;
                context.forceUpdate();
            }}


            centered
            title="Manage Views"
        >
            <LY.LY_MultiDropDown
                label="Pinned Views"
                isRequired
                selectedItems={pinnedTabs}
                data={data}
                displayProperty="name"
                name="name"
                error={state.errors.get('pinnedError')}
                valueProperty="listViewId"
                maxValues={2}
                isSingleSelect={false}
                onSelectedItemChange={(val, isSelected) => {

                    console.log('onSelectedItemChange isSelected', isSelected);

                    if (val)
                        val.isPinned = isSelected;

                    console.log('onSelectedItemChange val', val);

                    context.forceUpdate();
                }}

            />
            <div className="LY_ListFiltersModule_Save_Modal_Buttons">
                <LY.LY_ButtonCancel
                    onClick={() => {
                        state.isAllTabsSettingsOpen = false;
                        state.errors.clear();
                        context.forceUpdate();
                    }}
                >
                    Cancel
                </LY.LY_ButtonCancel>

                <LY.LY_Button
                    loading={state.isActionInProgress}
                    loaderProps={{ type: 'dots' }}
                    onClick={() => {
                        context.updateViews();
                    }}
                >
                    Save
                </LY.LY_Button>

            </div>
        </LY.LY_Modal>

    }

    return render();
}