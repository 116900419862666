import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { Badge } from '@mantine/core';
import React from 'react';
import { sizes } from '../../Constants/constants';

interface LY_DropDown_ViewProps extends LY.LY_StatusDropDownProps {

    // isShowingInPopover: boolean;

}

//type combinedProps = LY_DropDown_ViewProps & React.InputHTMLAttributes<HTMLInputElement>;

export const LY_DropDown_View = React.forwardRef<HTMLDivElement, LY_DropDown_ViewProps>((
    {
        ...props
    },
    ref
) => {

    const defaultBackgroundColor = '#C7C7C7';
    const context = LY.useStatusDropDownContext();
    const state = context.state;
    const variant = state?.model?.column?.styleVariant
    const badgeColor = state?.selectedItem?.text_color || props?.model?.column?.textColor || 'white';
    const badgeBackgroundColor = variant === 'transparent' ? '' : state?.selectedItem?.color || props?.model?.column?.valueBackgroundColor || defaultBackgroundColor; const badgeFontSize = state?.model?.column?.fontSize || '14px';
    const badgeRadius = state?.model?.column?.styleRadius || 0;
    const selectedItem = state.selectedItem;

    return (
        <MT.Tooltip 
        withArrow
        arrowSize={10}
        offset={-5}
        label={state.selectedItem?.help_text}  key={selectedItem?.id} hidden={!selectedItem?.help_text}  >
            <Badge
                ref={ref}
                onClick={() => context.setPopoverOpened(true)}
                radius={badgeRadius}
                className='LY_StatusDropDownDisplayBadge'
                style={{
                    border: variant === 'transparent' ? '' : '1px solid!important',
                    borderColor: variant === 'transparent' ? '' : badgeBackgroundColor,
                    height: '36px',
                    fontSize: badgeFontSize,
                    color: badgeColor,
                    backgroundColor: variant === 'outline' ? '' : badgeBackgroundColor,
                    cursor: 'pointer',
                    userSelect: 'none'
                }}
                variant={variant || 'filled'}
            >
                {state?.selectedItem?.name}
            </Badge>
        </MT.Tooltip>
    );
});
