import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Api from 'app-api';
import * as Icons from '@tabler/icons-react';


import './DetailPopupModal.css';
import { Badge, Tooltip, CloseButton, Modal, Tabs, rem } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';

interface DetailPopupModalProps {
  state: App.ListState; //this needs to be managed internally
  model: LY.DataItemModel;
  //onChange?: Function;

  //listId:string;
  //viewId:number;
}

export const DetailPopupModal: React.FC<DetailPopupModalProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();

  var newState = new App.DetailState();
  newState.vm = new App.DetailVm();

  const [ detailState, setDetailState ] = React.useState(newState); // Add this line
  const [ fkModel, setFKModel ] = React.useState(new LY.DataItemModel()); // Add this line
  const [ readOnly, setReadOnly ] = React.useState(false); // Add this line

  var vm = detailState.vm;

  React.useEffect(() => {

    var model = props.model;
    var propsVm = props.state.vm;

    //let vm = props.state.vm;
    console.log('DetailPopupModal useEffect model', model);
    //console.log('DetailPopupModal useEffect model.value', model?.value);

    if (model == undefined || !propsVm.isDetailPopupModalOpen)// || !model.column.fkListId)
      return;

     setReadOnly(props.state.forceReadOnly);

     getData();

   /*  setTimeout(() => {
      getData();
    }, 1000); */

  }, [ props.model?.value ]);


  async function getData() {


    var model = props.model;
    var propsVm = props.state.vm;

    console.log('DetailPopupModal getData model 1:', model);
    console.log('DetailPopupModal getData model propsVm:', propsVm);


    if (model == undefined || model?.column == undefined || model?.value == undefined)
      return;

    //console.log('getData model 2:', model);


/*     if (!model?.column?.fkListId || LIB.Common.isNullOrEmpty(model?.column.fkListColumnName))
      return;
 */

  var listId = propsVm.selectedDetailListId;
  var viewId = propsVm.selectedDetailViewId;

  if (!listId || !viewId)
      return;

    console.log('getData model 3:', model);

    console.log('getData model.column:', model?.column);

    //var sm = new Api.ListGetDataSm();
    var sm = new Api.DetailGetDataSm();

   // sm.listId = model?.column.fkListId;
    //sm.viewId = model?.column.fkListViewId;

   // sm.listId = model?.column.listId;
   // sm.viewId = model?.viewId;

    sm.listId = listId;
    sm.viewId = viewId;

    sm.name = model?.value;
    sm.filter = new Api.QueryFilter();
    var workspaceId = props.state.view?.workspaceId;
    var view = props.state?.view;
    var workspaceVm = App.AppBase.getCurrentWorkspaceVm();
    var currentWorkspace = workspaceVm?.selectedWorkspace;

    var col = model?.column;

    var condition = new Api.FilterCondition();
    condition.name = model.column.fkListColumnName!;
    condition.value = model?.value;
    condition.op = Api.FilterConditionOperatorEnum.Equal
    sm.filter.condition = condition;

    var dataService: App.DetailDataService | App.LinkedDetailDataService = new App.DetailDataService(workspaceId);
    var listService = new App.ListDataService(workspaceId);


    var detailService = new App.DetailDataService(workspaceId);

    console.log('getData view:', view);
    console.log('getData view isLinkedView:', view.isLinkedView);
    console.log('getData view fkIsLinkedWorkspace:', col?.fkIsLinkedWorkspace);

    if (view.isLinkedView) {
      dataService = new App.LinkedDetailDataService(currentWorkspace?.workspaceId);
      dataService.linkedWorkspaceId = workspaceId;
    }
    else if(col?.fkIsLinkedWorkspace){

      //    var linkedWorkspace = workspaceVm?.linkedWorkspaces?.find(x => x.workspaceId == workspaceId);
      var linkedWorkspaceId = col?.fkWorkspaceId;
      dataService = new App.LinkedDetailDataService(currentWorkspace?.workspaceId);
      dataService.linkedWorkspaceId = linkedWorkspaceId;
    }

    if(dataService.linkedWorkspaceId )
     setReadOnly(true);

    console.log('DetailPopupModal condition:', condition);

    /*   console.log('DetailPopupModal sm.filter:', sm.filter);
      console.log('DetailPopupModal state:',props.state);
      console.log('DetailPopupModal view:',props.state.view);
      console.log('DetailPopupModal currentWorkspace:',currentWorkspace);
   */

    detailState.hasDataLoaded = false;

    //listService.getRecords(sm,
    dataService.getDetails(sm,
      //onSuccess
      (result: App.DetailState) => {
        // console.log('DetailPopupModal onSuccess result:', result);

        vm.apiErrorMessage = '';
        LIB.ObjectHelper.setData(result, detailState, 'vm');
        vm.isActionInProgress = false;

        //var first = result.records[0];
        var data = result.data;

        // console.log('DetailPopupModal onSuccess data:', data);
        if (data) {
          fkModel.row = data;
          setFKModel(fkModel);
        }

        detailState.hasDataLoaded = true;
        setDetailState(detailState);

        console.log('DetailPopupModal onSuccess fkModel:', fkModel);

        forceUpdate();

      },
      //onError
      (error: any) => {

        console.error('DetailPopupModal onError:', error);

        vm.apiErrorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        forceUpdate();
        //setServerError(errorMessage);
      });


    vm.isActionInProgress = true;

    //console.log('DetailPopupModal vm.isActionInProgress 2', vm.isActionInProgress);

    forceUpdate();

  }

 

  function onOpenChange(open: boolean) {
    var propsVm = props.state.vm;

    propsVm.isDetailPopupModalOpen = open;

    /*   if(!open)
      reset(newColumn);
   */
    forceUpdate();
  }

  function getChildLists() {
    var state = props.state;

    var relationshipLists = detailState.relationshipLists;


    if (relationshipLists == undefined || relationshipLists.length == 0 || !detailState.hasDataLoaded)
      return null;

    var workspaceId: number | undefined = state.view?.workspaceId;
    var currentWorkspace = App.AppBase.currentWorkspace;
    var linkedWorkspaceId: number | undefined = undefined;


    console.log('getChildLists relationshipLists', relationshipLists);
    var view = state.view;

    if (view.isLinkedView) {
      linkedWorkspaceId = state.view?.workspaceId;
      workspaceId = currentWorkspace?.workspaceId;
    }

    console.log('getChildLists linkedWorkspaceId', linkedWorkspaceId);
    console.log('getChildLists workspaceId', workspaceId);

    

    const iconStyle = { width: rem(12), height: rem(12) };


    return <Tabs
     key={'childTabs' + relationshipLists[ 0 ].listId}
      defaultValue={'tab' + relationshipLists[ 0 ].listId} className='ListViewRelationshipTabs'>

      <Tabs.List>

        {
          relationshipLists.map((item, index) => (
            <Tabs.Tab 
            key={'tab' + item.listId+index} 
            value={'tab' + item.listId} leftSection={<Icons.IconForms style={iconStyle} />}>
              {item.viewName} 
            </Tabs.Tab>
          ))
        }

      </Tabs.List>

 


      {
        relationshipLists.map((item, index) => {

          if(!item.viewName)
            return null;

            console.log('getChildLists relationshipLists filter', item.filter);


          return <Tabs.Panel key={'tabPanel' + item.listId} value={'tab' + item.listId} className='ListViewRelationshipTabPanel'>
 
             <LY.ListTableView
             {...item}
              key={'listView' + item.listId+index}
              
              filter={item.filter}
              forceFilter={true}
              workspaceId={workspaceId}
              linkedWorkspaceId={linkedWorkspaceId || item.linkedWorkspaceId}
              hideFooterWhenNoPaging={true}
            //showFilters={true}
             disableNameClick={true}
             forceReadOnly={true}
             hideCheckboxSelection={true}
             tableHeight='100%'
             minTableHeight='100px'
            />  

       

          </Tabs.Panel>

  })
      }

      {/* 
      <Tabs.Panel value="approvedParts" className='DetailContainerTabPanel'>
      Part History
     
      </Tabs.Panel>

      <Tabs.Panel value="history" className='DetailContainerTabPanel'>
        Part History

      </Tabs.Panel>
 */}


    </Tabs>


  }

  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var state = props.state;
    var workspaceId = state.view?.workspaceId;
    var propsVm = state.vm;
    let vm = detailState.vm;
    var selectedItem = propsVm.selectedItem;
    var column = props.state.newColumn

    // console.log('DetailPopupModal render vm.isDetailPopupModalOpen', vm.isDetailPopupModalOpen);
    //  console.log('DetailPopupModal render vm.isActionInProgress', vm.isActionInProgress);

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || column == undefined || !propsVm.isDetailPopupModalOpen)
      return null;

    //Api.ListGetDataSm 

     console.log('DetailPopupModal render state', state);
     console.log('DetailPopupModal render fkModel', fkModel);
     console.log('DetailPopupModal render selectedItem', selectedItem);
     

    var relationshipLists = detailState.relationshipLists;

    return (
      <LY.LY_Modal
        id='DetailPopupModalId'
        opened={propsVm.isDetailPopupModalOpen}
        onClose={() => onOpenChange(false)}
        title={` ${props.model?.column.displayName}: ${props.model?.value}`}
        //scrollAreaComponent={ScrollArea.Autosize}
        centered
        overlayProps={{
          backgroundOpacity: 0.1,
          blur: 0,
        }}
        transitionProps={{ duration: 0, timingFunction: 'linear' }}

        size={'50vw'}

          
      //className='DetailPopupModal'
          className={'LY_Global_Modals'}
      >

        <App.MainContainer

          errorMessage={vm.apiErrorMessage}
          loading={vm.isActionInProgress}
          hasDataLoaded={state.hasDataLoaded}
          showContentWhenInProgress={true}
          showModalForLoading={true}
          className='DetailPopupModalContainer'
          
        >

          {/*       <App.DetailContainer state={props.state} 
        //model={props.model}
         /> */}

          <App.DefaultListTableForm state={detailState}
            model={fkModel}
           // model={selectedItem}
            onClose={() => onOpenChange(false)}
            formContainerClassName={'DetailPopupModalFormContainer'}
            excludeFKMultiple={true}
            excludeButtons={true}
            readOnly={readOnly }
          />


          {getChildLists()}  

        </App.MainContainer>

      </LY.LY_Modal>


    );
  }



  return render();
};
