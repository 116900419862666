import React, { ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';
import * as RIcons from '@radix-ui/react-icons';
import * as LIB from '_LIB';
import * as Icons from '@tabler/icons-react';
import * as MT from '@mantine/core';

import './LY_DropDown.css';

import { Button, Badge, Tooltip } from '@mantine/core';
import { CloseButton, Combobox, Input, InputBase, ComboboxOptionProps } from '@mantine/core';

import { useToggle } from '@mantine/hooks';


type combinedProps = LY.LY_DropDownProps;

export const LY_DropDown = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    name,
    data,
    value,
    // labelProperty,
    displayProperty = '',
    valueProperty = '',
    placeholder,
    defaultValue,
    inputClassName,
    model,
    onItemClick,
    onValueChange,
    onChange,
    viewRenderer,
    editRenderer,
    style,
    selectedItem,
    // target = '_blank',
    readOnly,
    //linkProps,
    ...props
  },
  ref // This is the forwarded ref
) => {


  const forceUpdate = LIB.useForceUpdate();

  //const [ selectedItem, setSelectedItem ] = React.useState(props.selectedItem);
  const [ selectedItemDisplayValue, setSelectedItemDisplayValue ] = React.useState<string>(getSelectedItemValue(selectedItem));

  const [ localData, setLocalData ] = React.useState<any[]>([]);
  const [ searchText, setSearchText ] = React.useState('');

  //console.log('LY_DropDown selectedItem: ',selectedItem);


  React.useEffect(() => {
    // console.log(`LY_DropDown useEffect name:${name} selectedItem: `, selectedItem);

    if (selectedItem) {
      var displayVal = getSelectedItemValue(selectedItem);
      setSelectedItemDisplayValue(displayVal);
    }
    else
      setSelectedItemDisplayValue('');

  }, [ selectedItem ]); // This effect runs whenever selectedItem changes , 

  React.useEffect(() => {
    //console.log('LY_DropDown useEffect onLocalValueChange data: ', data);

    if (!data || !Array.isArray(data))
      data = [];

    setLocalData(data);

  }, [ data ]); // This effect runs whenever selectedItem changes


  /*    React.useEffect(() => {
      //console.log('LY_DropDown useEffect onLocalValueChange selectedItemDisplayValue: ', selectedItemDisplayValue);
    }, [ selectedItemDisplayValue ]); // This effect runs whenever selectedItem changes  
   */



  function getOptionsItems(combobox: MT.ComboboxStore | undefined):React.ReactElement[]|undefined {

    if(props.optionsItemRenderer)
      return props.optionsItemRenderer(combobox);

    let options = localData.map((item, index) => {
 
        // console.log('LY_DropDown item item: ',item);
        // console.log('LY_DropDown item index: ',index);

        var isActive = (item[ valueProperty! ] === selectedItem?.[ valueProperty! ]);
        // console.log('LY_DropDown isActive: ',isActive, item[labelProperty]);
        var activeClassName = isActive ? 'LY_DropDownOptionActive' : '';
        var key = `ComboboxOptionKey_${valueProperty}_${item[ valueProperty! ]}_${index}`;

        //console.log('LY_DropDown item key: ',key);

        return <Combobox.Option
          key={key}
          className={'LY_DropDownOption' + ' ' + activeClassName}
          value={item[ valueProperty ]}
          selected={isActive} >

          {props.optionItemDisplayRenderer? props.optionItemDisplayRenderer(item,combobox):item[ displayProperty ]}
        {/*   {item[ displayProperty ]} */}


        </Combobox.Option>
      });

    return options;
  }

  function getSelectedItem(val: any = value) {

    let item = data?.find(x => x[ valueProperty ] === val);

    return item;

  }

  function getSelectedItemValue(item: any) {

    if (!item)
      return '';

    let result = item[ displayProperty ] || '';

    // console.log('LY_DropDown getSelectedItemValue result 1: ',result);

    result = LY.ListColumnManager.getCalculatedValue(result, item, result);

    //console.log('LY_DropDown getSelectedItemValue result 2: ',result);

    return result;

  }


  /*   function getViewMode() {
  
      // if(model?.rowId=='7da8432c-ec88-47bd-93e0-584d530bfd11')
      //   console.log('LY_DropDown getViewMode model: ',model);
  
  
      let value = model?.value;
      var col = model?.column;
  
  
      if (LIB.Common.isNullOrEmpty(href))
        href = value;
  
      if (col?.clientPrepend)
        href = `${col.clientPrepend}${href}`;
  
      if (col?.clientAppend)
        href = `${href}${col.clientAppend}`;
  
      if (LIB.Common.isNullOrEmpty(title))
        title = href;
  
      var displayValue =  LIB.StringHelper.maxLength(value, 30);
     
      if(href && !href.toLocaleLowerCase().startsWith('http'))
        href = `http://${href}`;
  
      return <a
      className='LY_DropDownLink'
      target={target}
      href={href}
      title={title}
      {...linkProps}
    // onClick={onLocalNameClick}
    >{displayValue}</a>
  
    } */



  function onLocalValueChange(m: LY.DataItemModel, val: string | null, inSelectedItem:any, optionProps?: ComboboxOptionProps | undefined) {

    //  console.log('LY_DropDown onLocalValueChange model: ', m);
    //  console.log('LY_DropDown onLocalValueChange val: ', val);
    // console.log('LY_DropDown onLocalValueChange optionProps: ', optionProps);
 
    if (model)
      model.value = val?.trim();

    value = val;


    let item = data?.find(x => x[ valueProperty ] === val);


    // console.log('LY_DropDown onLocalValueChange item: ', item);

    //todo setSelectedItem(item);

    //  console.log('LY_DropDown onLocalValueChange selectedItem: ', selectedItem);
    // console.log('LY_DropDown onLocalValueChange val: ', val);

    var displayVal = getSelectedItemValue(item);
    //  console.log('LY_DropDown onLocalValueChange displayVal: ', displayVal);

    setSelectedItemDisplayValue(displayVal);

    //forceUpdate();
    //   console.log('LY_DropDown onLocalValueChange selectedItemDisplayValue: ', selectedItemDisplayValue);

    //  forceUpdate();

    if (onValueChange)
      onValueChange(model!, item, optionProps);

    if (onChange)
      onChange(item, optionProps);
  }

  function onSearchChange(value: string, combobox?:MT.ComboboxStore|undefined) {

    var filtered: any[] | undefined = [];

    console.log('LY_DropDown onSearchChange value: ', value);
 
      if (!LIB.Common.isNullOrEmpty(value)) {
        filtered = data?.filter(
          x => x[ displayProperty ]?.toLowerCase()?.includes(value.toLowerCase()) == true ||
            x[ valueProperty ]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()) == true
        );
      }
      else
        filtered = data;

      console.log('LY_DropDown onSearchChange filtered: ', filtered);

      if (filtered)
        setLocalData([ ...filtered ]);
      else 
        setLocalData(data || []);
   
    if (props.onSearchChange) 
      props.onSearchChange(value,combobox,filtered);


    setSearchText(value);
  }

  function render() {

    /*   if (mode == 'view')
        return getViewMode();
  
      return getEditMode();
   */

    var result = <LY._LY_DropDownBaseViewEdit
      ref={ref}
      name={name}
      selectedItemDisplayValue={selectedItemDisplayValue}
      data={data}
      displayProperty={displayProperty}
      valueProperty={valueProperty}
      selectedItem={selectedItem}
      value={value}
      style={style}
      {...props}
      placeholder={placeholder}
      model={model}
      // viewRenderer={getViewMode()}
      // editRenderer={getEditMode()}
      onValueChange={onLocalValueChange}
      readOnly={readOnly}
      optionsItemRenderer={getOptionsItems}
      optionsRenderer={props.optionsRenderer}
      onSearchChange={onSearchChange}
      optionsContainerClassName={props.optionsContainerClassName}
      optionsContainerStyle={props.optionsContainerStyle}
      optionsMaxHeight={props.optionsMaxHeight}
      footerRenderer={props.footerRenderer}
    />;

    return result;

  }


  function renderWithMemo() {

    return React.useMemo(() => render(), [ data, selectedItem, value ]);
  }

  return render();
  // return renderWithMemo();

});


