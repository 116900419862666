import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class InitialDataVm  extends LIB.BaseVmModel {
 
    public currentUser:App.AppUser = new App.AppUser();
    public userRoleTypes:App.UserRoleType[];
    public testSessionVal:string;
    public apiEnvName:string;
    public sessionHasCurrentUser:string;
    public appSettings:App.AppInfoVm;
    public lookupTypes:Api.LookupType[];

    constructor() { 
 
        super();
        this.userRoleTypes=[];

    }
     


}