import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class WorkspaceVm  extends LIB.BaseVmModel {
 
 
    public isListSettingsOpen:boolean=false;
    public isItemDeleteOpen:boolean=false;

    public isNewListModalOpen:boolean=false;
    public isNewFolderModalOpen:boolean=false;

    public isItemContextMenuOpen:boolean=false;

   public selectedItemElement:any;

    public selectedItem:Api.WorkspaceItem | undefined;
    public selectedListId:string | undefined;
   // public selectedWorkspaceId:number | undefined;

    public selectedItemOriginal:Api.WorkspaceItem;
 
    public newItem:Api.WorkspaceItem = new Api.WorkspaceItem();


    public workspaces:Api.Workspace[]=[];
    public linkedWorkspaces:Api.Workspace[]=[];
    public allWorkspaces:Api.Workspace[]=[];

    public selectedWorkspace:Api.Workspace = new Api.Workspace();
   // public selectedWorkspaceTree:Api.WorkspaceTree = new Api.WorkspaceTree();

    public hasWorkspacesDataLoaded: boolean = false;
    
    public isNewWorkspaceScreenOpen: boolean = false;
    public isUpdateWorkspaceScreenOpen: boolean = false;


    public isCopyListScreenOpen: boolean = false;
    public isCopyWorkspaceScreenOpen: boolean = false;
    
    public isWorkspaceDeleteOpen: boolean = false;
    public isWorkspaceDeleteInProgress: boolean = false;

    public disableOnSelectRouteToPage: boolean = false;

    
    constructor() { 
        super();
    }
     
    
    public static setupNewItem(vm:App.WorkspaceVm, createIn:string | undefined = undefined){

        if(!vm)
        return;

        var newItem = new Api.WorkspaceItem();

        if(vm.selectedWorkspace?.workspaceId)
        newItem.workspaceId = vm.selectedWorkspace?.workspaceId;

        if(createIn)
        newItem.parentId = createIn;
        else if(vm.selectedItem){

            if(vm.selectedItem?.isFolder)
            newItem.parentId = vm.selectedItem?.workspaceItemId;
            else
            newItem.parentId = vm.selectedItem?.parentId;
        }
 
   

/*         console.log('setupNewItem ', this.newItem);
        console.log('setupNewItem workspaceItemId', this.selectedItem?.workspaceItemId);
        console.log('setupNewItem parentId', this.selectedItem?.parentId); */

        return newItem;
    }



}