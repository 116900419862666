
export enum ListColumnTypeEnum
{
    SingleLineText = 1,
    LongText = 2,
    Date = 3,
    DateAndTime = 4,
    Number = 5,
    Integer = 6,
    Phone = 7,
    Email = 8,
    AutoNumber = 9,
    User = 10,
    Dropdown = 11,
    MultiSelectDropdown = 12,
    CheckBox = 13,
    Location = 14,
    Website = 15,
    Status = 16,
    Money = 17,
    RadioGroup = 18,
    Slider = 19,
    Switch = 20,
    Progress = 21,
    Button = 22,
    Relationship = 23,
    HoverText = 24,
    PopupButton = 25,
    Badges = 26,
    DateUpdated = 27,
    DateCreated = 28,
    DateDeleted = 29,
    UpdatedBy = 30,
    CreatedBy = 31,
    DeletedBy = 32,
    People = 33,
    Guid = 34,
    AutoGuid = 35,
    RichText = 36,
    Month = 37,
    Time = 38,
    Week = 39,
    Password = 40,
    BigInteger = 41,   
    CalculatedNumber = 42,
    CalculatedValue = 43,
    Color = 44,
    Tags = 45,

    GuidArray = 46,
    String = 47,
    StringArray = 48,
    IntegerArray = 49,

    Other = -1,
}
