import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import * as Icons from '@mui/icons-material/';

///import { NavLink } from 'reactstrap';
import { Drawer, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material/';
import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { CSSProperties } from "react";

//import { RouterState } from 'connected-react-router';
import './WorkspaceMenu.css';

import '../Menus/LeftMenu.css';

/* import {
    UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider,
    ControlledTreeEnvironment, TreeItemIndex, TreeItem, DraggingPosition, InteractionMode
} from 'react-complex-tree';
import 'react-complex-tree/lib/style-modern.css'; */



import { TreeItemProps } from '@mui/lab';

//import  WorkspaceItemContextMenu from './WorkspaceItemContextMenu';
import ReactDOM from 'react-dom';
//import { createDefaultRenderers } from './createDefaultRenderers';
import { ListSettingsModal } from './Settings/ListSettingsModal';
import WorkspaceItemContextMenu from './WorkspaceItemContextMenu/WorkspaceItemContextMenu';
import { CreateListModal } from './Create/CreateListModal';
import NavigationMenuDemo from '_LY_Components/NavMenu/NavigationMenuDemo/NavigationMenuDemo';
import NavigationMenuDemo2 from '_LY_Components/NavMenu/NavigationMenuDemo2/NavigationMenuDemo2';
import { LY_TreeMenu } from '_LY_Components/TreeMenu/LY_TreeMenu/LY_TreeMenu';
import { CreateFolderModal } from './Create/CreateFolderModal';
import { WorkspaceDropdown } from 'Components/WorkspaceDropdown/WorkspaceDropdown';
import { CopyListScreen } from './Copy/CopyListScreen/CopyListScreen';




const drawerWidth = 240;
/* 
interface WorkspaceMenuProps {
 
    selectedItem: string;

}
 */
export const WorkspaceMenu: React.FC<App.GlobalState> = (props) => {


    var selectedTab: string | undefined;
    var params = useParams();
    var location = useLocation();
    var locationState = location.state;
    const forceUpdate = LIB.useForceUpdate();

    /*   React.useEffect(() => {
          console.log("WorkspaceMenu useEffect update");
  
      }); */

    //rct-tree-item-button selected btn

    var globalVm = props.globalUIState;
    var vm = props.workspaceVm;


    // console.log('WorkspaceMenu globalUIState:', vm);

    var appPath = "";//App.AppBase.appInfo.appPath;

    // console.log('router: ');
    // console.log(this.props.location);

    // var selectedItem = App.AppBase.history?.location?.pathname;
    var selectedItem = App.AppBase.location?.pathname;
    var currentUser = App.AppBase.currentUser;


    const [ isDeleteItemOpen, setIsDeleteItemOpen ] = useState(false);




    const [ focusedItem, setFocusedItem ] = useState();
    /*     const [ selectedItems, setSelectedItems ] = useState<TreeItemIndex[]>([]);
        const [ expandedItems, setExpandedItems ] = useState<TreeItemIndex[]>([]);
        const [ draggedItem, setDraggedItem ] = useState<TreeItemIndex | null>(null);
        const [ dropTarget, setDropTarget ] = useState<TreeItemIndex | null>(null); */
    // const [ treeData, setTreeData ] = useState<any>([]);


    /*     if (!currentUser?.isAuthenticated)
                return null;
      */
    /* 
        if (currentUser?.isAdmin ||
            currentUser?.hasRole(App.UserRoleTypeEnum.TeamMember) ||
            currentUser?.hasRole(App.UserRoleTypeEnum.Member)) {
    
           
    
        }
     */

    var ListItemStyle: CSSProperties = {
        paddingTop: 4,
        paddingBottom: 4,
    };

    var variant: any = 'permanent';

    if (globalVm.isMobile)
        variant = undefined;

    //console.log('WorkspaceMenu render:', vm);

    
    //console.log("Tree treeData:", treeData);

 
   

    function onDeleteConfirmClick() {

        console.log("Tree onConfirmDeleteClick");
        vm.isItemDeleteOpen = false;
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_DELETE_REQUEST, vm);
        

    }

    function onDeleteCancelClick() {
        console.log("Tree onConfirmDeleteClick");

        vm.isItemDeleteOpen = false;
        setIsDeleteItemOpen(false);

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_MODAL_CHANGE, vm);


    }

    function onMenuItemContextMenuClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: Api.WorkspaceItem) {
        console.log('onMenuItemContextMenuClick', item);

        e.preventDefault();

        var vm = new App.WorkspaceVm();
        vm.selectedItemElement = e.currentTarget.parentNode;
        vm.isItemContextMenuOpen = true;
        //vm.disableOnSelectRouteToPage = true;
        vm.selectedItem = item;

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

    };

    function onSelectedItemsChanged(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: Api.WorkspaceItem) {
        console.log('WorkspaceMenu onSelectedItemsChanged', item);

        //e.preventDefault();
        var vm = new App.WorkspaceVm();
        vm.selectedItemElement = e.currentTarget.parentNode;
        vm.selectedItem = item;

        console.log('onSelectedItemsChanged', vm);

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

    };

    function onDeleteClick(e: React.KeyboardEvent<HTMLDivElement>, item: Api.WorkspaceItem) {
        console.log('onDeleteClick', item);

        //e.preventDefault();
        var vm = new App.WorkspaceVm();
        vm.selectedItemElement = e.currentTarget.parentNode;
        vm.isItemDeleteOpen = true;
        vm.errors.clear();
        vm.apiErrorMessage='';
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_MODAL_CHANGE, vm);

    };

    const onDuplicateListClose = () => {

        vm.isCopyListScreenOpen = false;
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

    }


    //console.log("WorkspaceMenuD: vm.workspaces", vm.workspaces);

    //console.log("WorkspaceMenuD: vm.selectedWorkspace", vm.selectedWorkspace);

 
    //console.log("WorkspaceMenuD: render treeData", treeData);
   // console.log("WorkspaceMenuD: isItemDeleteOpen", vm.isItemDeleteOpen);

    console.log("WorkspaceMenuD: selectedItem", vm.selectedItem);


    return <div  >
        {/* 
        <Drawer
            className="WorkspaceMenuDrawer"
            variant={variant}
            classes={{
                paper: "WorkspaceMenuDrawerPaper",
            }}
            anchor="left"
            open={globalVm.isMobileMenuOpen}
        >
 */}


        <div className="drawerContainer" >


            {/* 
            <List component="nav" aria-label="Main Menu"
                className="LeftMenuList"
               // onChange={(e: any) => console.log('Menu Change')}
                style={{marginTop:0 }}
            >
            

                <ListItem
                    key="/"
                    component={RouterLink}
                    to="/"
                    //onClick={}
                  
                    button
                        selected={(selectedItem == "/" || selectedItem == "/")}
                >
                    
                    <ListItemIcon  style={{marginLeft:11 }}>
                        <Icons.Home />
                    </ListItemIcon>
                    <ListItemText primary="My Work" />
                    </ListItem>

               <ListItem
                    key="Editor"
                    component={RouterLink}
                    to="/Editor"
                    //onClick={}
                  
                    button
                        selected={(selectedItem == "/Editor" )}
                >
                    
                    <ListItemIcon  style={{marginLeft:11 }}>
                       
                        <Icons.People />
                    </ListItemIcon>
                    <ListItemText primary="Editor" />
              
                </ListItem>



             
                </List> */}
            {/*  <InboxIcon /> */}

         {/*    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                <LY.LY_StatusSelect

                    label="Workspace"
                    name="workspace"
                    data={vm.workspaces}
                    labelProperty="name"
                    valueProperty='workspaceId'

                    value={vm.selectedWorkspace || ''}
                    //variant="standard"
                    // textFieldStyle={textFieldStyle}
                    // textFieldStyle={{   width: '100%' }}
                    //  textFieldClassName='WorkspaceMenuTextField'
                    tag='CRM'
                />

                <Button
                    color="primary"
                    variant="contained"
                    style={{ padding: 2, minWidth: 10 }}
                //onClick={this.onSaveClick.bind(this)}
                >   <Icons.AddOutlined />          </Button>

            </div> */}


             <WorkspaceDropdown state={props} /> 

            {/*  --------LY_TreeMenu START------------- */}

            <LY_TreeMenu
                preItemIcon={<Icons.ViewStreamOutlined />}
                name='mainWorkspaceNav'
                items={vm.selectedWorkspace?.workspaceItems}
                onItemContextMenuClick={onMenuItemContextMenuClick}
                onSelectItem={onSelectedItemsChanged}
                selectItem={vm.selectedItem}
                onDeleteKeyPress={onDeleteClick}
            />
            {/*  --------LY_TreeMenu END------------- */}
            {/* 
<hr style={{marginTop:10}} /> 
                <LY.LY_TreeMenuDnD
  preItemIcon={<Icons.ViewStreamOutlined />}
  name="mainWorkspaceNav"
  items={vm.selectedWorkspace?.workspaceItems}
  onItemContextMenuClick={onMenuItemContextMenuClick}
  onSelectItem={onSelectedItemsChanged}
  selectItem={vm.selectedItem}
  onDeleteKeyPress={onDeleteClick}
/> */}

        </div>


        {/*       <Divider /> */}


        {/*    </Drawer> */}

        <ListSettingsModal vm={vm} />
        <CreateListModal vm={vm} />
        <CreateFolderModal vm={vm} />

        <WorkspaceItemContextMenu vm={vm} />


        <CopyListScreen  
              vm={vm}
              onClose={() =>{onDuplicateListClose()}}
         />

        <LIB.ConfirmModal
            visible={vm.isItemDeleteOpen}
            onCancelClick={() => onDeleteCancelClick()}
            onConfirmClick={() => onDeleteConfirmClick()}
            title={`Confirm Delete: ${vm.selectedItem?.name}`}
            // content={`Are you sure you want to delete item "${vm.selectedItem?.name}"?`}

            content={
                <div>
                    <div>Are you sure you want to delete item <b>{vm.selectedItem?.name}</b>?</div>

                    <MT.Alert variant="light" color="red"
                        title={`DELETE WARNING: ${vm.selectedItem?.name}`}
                        style={{ marginTop: 10, minHeight: 50, padding: 8 }} >

                        THIS WILL DELETE ALL ASSOCIATED DATA IN THE LISTS/FORMS/VIEWS

                    </MT.Alert>




                </div>}

            confirmButtonLabel="Delete"
            errorMessage={vm.apiErrorMessage}
            disabled={vm.isActionInProgress}
        />

    </div>;
}
//}
export default connect(
    (state: App.AppState) => ({ ...state.globalData })
)(WorkspaceMenu as any);


