import "./LY_StaticDropDown_View.css"

import * as Api from 'app-api'
import * as Icons from '@tabler/icons-react';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { Badge, Tooltip } from '@mantine/core';

import React from 'react';

interface LY_StaticDropDown_ViewProps extends LY.LY_StaticDropDownProps { }

export const LY_StaticDropDown_View = React.forwardRef<HTMLDivElement, LY_StaticDropDown_ViewProps>((
    props, ref
) => {

    const context = LY.useStaticDropDownContext();
    
    const state = context.state;
    const column = state?.model?.column;
    //const styleIsFullWidth = state?.model?.column?.styleIsFullWidth || !state.allowMultipleSelection;

    const variant = state?.model?.column?.styleVariant || "filled";
    const badgeFontSize = state?.model?.column?.fontSize || '12px';
    const badgeRadius = state?.model?.column?.styleRadius || 10;

    let showItems = state.selectedItems


    if(state.allowMultipleSelection){

        if (isFullWidthStyle()) {
            showItems = state?.selectedItems?.slice(0, 1)
        } else if (state?.selectedItems && state?.selectedItems?.length > 3 && !isFullWidthStyle()) {
            showItems = state?.selectedItems?.slice(0, 3)
        }
    }
 


    function isFullWidthStyle(item: Api.SystemLookupType|undefined=undefined) {

        if(state.allowMultipleSelection)
            return false;

        if (state?.model?.column?.styleIsFullWidth || (item && !item.color))
            return true;

        return false;
    }



    function getCloseIcon(id: string, badgeColor: string) {
        if (state.readOnly) return null
        return <Icons.IconX
            width={12}
            height={12}
            color={badgeColor}
            onClick={() => {
                if (state?.selectedItems) {
                    state.selectedItems = state?.selectedItems.filter(i => i.id !== id);
                    context.forceUpdate();
                }
            }}
        />
    }
    function countRenderer() {


        if (state?.selectedItems && state?.selectedItems?.length > 3 && 
            state.allowMultipleSelection && !isFullWidthStyle()) {

            return <Badge
                radius={badgeRadius}
                style={{
                    backgroundColor: "#f1f3f5",
                    color: "#000",
                    fontWeight: 500,
                    width: '50px',
                    fontSize: badgeFontSize,
                    cursor: 'pointer',
                    userSelect: 'none',
                    display: 'flex',
                }}
                variant={variant}
            >
                +{state.selectedItems?.length - 3}
            </Badge>
        }
        return null
    }

    

    return <div
        onClick={() => context.setPopoverOpened(true)}
        ref={ref}
        className={props.inputContainerClassName}
        style={props.style}
       // className='LY_StaticDropDownDisplay'
    >
        {showItems?.map((selectedItem) => {

            const styleIsFullWidth = isFullWidthStyle(selectedItem);
            const badgeColor = selectedItem?.text_color || '#000';

            let icon = null;
            if (state.allowMultipleSelection) {
                icon = getCloseIcon(selectedItem.id!, badgeColor)
            }

            if (isFullWidthStyle(selectedItem)) {

                icon = <Icons.IconCaretDownFilled size={16} className="LY_StaticDropDownDisplayArrow"/>;
            }


            let badgeBackgroundColor = "transparent";

            badgeBackgroundColor = selectedItem?.color || props?.model?.column?.valueBackgroundColor || "transparent";
            if (variant === 'outline') badgeBackgroundColor = "transparent";

            const border = variant === 'transparent' ? '' : `1px solid ${selectedItem?.color || '#f7e4fd'}!important`;

            return (
                <Tooltip 
                withArrow
                arrowSize={10}
                label={selectedItem?.help_text} key={selectedItem?.id} hidden={!selectedItem?.help_text} >
                    <Badge
                        fullWidth={styleIsFullWidth}
                        key={selectedItem?.id}
                        radius={badgeRadius}
                        className='LY_StaticDropDownDisplayBadgeView'
                        styles={{
                            label: {
                                overflow: 'unset',
                                justifyContent: 'space-between',
                            },
                            root: {
                                justifyContent: (styleIsFullWidth) ? "space-between" : 'flex-center'
                            }
                        }}
                        style={{
                            width: !styleIsFullWidth ? 'fit-content' : '100%',
                            border,
                            color: badgeColor,
                            backgroundColor: badgeBackgroundColor,
                            cursor: 'pointer',
                            userSelect: 'none',
                            fontWeight:(!selectedItem?.color)?'unset':undefined,
                            fontSize:(styleIsFullWidth)?'unset':'12px',
                            padding:(styleIsFullWidth)?'5px':'inherit',
                            display: 'flex',
                        }}
                        size={badgeFontSize}
                        variant={variant}
                        rightSection={icon}
                    >
                        {selectedItem?.name}
                    </Badge>
                </Tooltip>
            );
        })}
        {countRenderer()}
    </div >
});
