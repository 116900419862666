import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
 
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';


//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";

import './BillingPricingTable.css';



interface BillingPricingTableProps {
  billingPlans: Api.BillingPlan[];
  pricingGridClassName?: string;
  registerSm?: Api.RegisterFormSm;
  offer?: Api.Offer;
}

export const BillingPricingTable: React.FC<BillingPricingTableProps> = ((
  {
    billingPlans,
    offer,
    ...props

  }) => {

  const libForceUpdate = LIB.useForceUpdate();
  const [ vm, setVm ] = React.useState<LIB.BaseVmModel>(new LIB.BaseVmModel());

  const currentUser = App.AppBase.currentUser;

  /* 
   function resetData() {
     console.log('BillingPricingTable resetData');
     var profile = props.state.profile;
 
     profile.setData(props.state.profileOriginal);
   }
   */


  function forceUpdate() {

    var newVm = { ...vm, errors: vm.errors };

    setVm(newVm);
    libForceUpdate();

  }


  function onSubscribeClick(plan: Api.BillingPlan) {
    console.log('onSubscribeClick plan', plan);



    if (vm.isActionInProgress) {
      console.log('onSubscribeClick ActionInProgress..');
      return;
    }

    if (!currentUser) {

      var sm = props.registerSm;
      var coupon = '';
      if (sm?.coupon)
        coupon = `&coupon=${sm?.coupon}`;

      console.log('onSubscribeClick currentUser not found..');
      App.RouteManager.routeToPath(`/Register?planId=${plan.billingPlanId}${coupon}`)
      return;
    }

    var stripeSm =  {
      plan : plan,
      offer : offer
    };

    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_BILLING_CREATE_STRIPE_SESSION_REQUEST, stripeSm);

    vm.isActionInProgress = true;

    forceUpdate();

  }

  function getOfferInfoBox(){

    if(!offer || !offer?.isVisibleOnSignup)
      return null;

    return  <MT.Alert className='LY_OfferInfoBox'
    variant="light" color="teal" radius="lg"

    title={offer.groupName+' Offer'} icon={<Icons.IconInfoCircle />}>
      {offer.description}
    </MT.Alert>

   /*  return <div className='LY_OfferInfoBox'>

      <div className='LY_OfferInfoBoxTitle'>
        {offer.groupName}
      </div>
      <div className='LY_OfferInfoBoxDescription'>
        {offer.description}
      </div>
 
    </div> */

  }

  function getPlanBox(plan: Api.BillingPlan, priorPlan: Api.BillingPlan, index: number) {

    if (plan == undefined)
      return null;

    let offerPrice = "";

    var startIcon: any = undefined;

    var priceTag = "month";

    var price: number = plan.monthlyPrice || -1;

    if (!price || price < 1) {
      price = plan.annualPrice || -1;
      priceTag = "year";
    }
    if (!price || price < 1) {
      price = plan.quarterlyPrice || -1;
      priceTag = "quarter";
    }


    if (!price || price < 1)
      price = plan.annualPrice || -1;


    var discountTag;
    if (offer?.discountPct && offer.discountPct > 0) {
      offerPrice = (price - (price * offer.discountPct / 100)).toFixed(0);
      discountTag = `${offer.discountPct}% OFF`;
    }

    var description = LIB.StringHelper.replaceNewLinesWithBR(plan?.description);

    var buttonLabel = plan.buttonLabel || 'Subscribe';

    if(offer?.trialDays && offer?.trialDays>0){
      buttonLabel = `Try ${offer.trialDays} Days For Free`;
    }


    return <div className='LY_PricingGridItem' key={'plan' + plan.billingPlanId}   >


      <div className="price-and-features-card hide-ctas mb-5 mb-lg-0">
        {plan.isMostPopular ? (
          <div className="tier-color-bar tier-color-bar-most-popular"  ></div>
        ) : (
          <div className="tier-color-bar"></div>
        )}
        <div className="price-card-price-container">
          <h2 className="price-card-tier-name">

            <span className="tier-name-text">{plan.name}</span>
            {plan.isMostPopular && (
              <span className="tier-name-extra with-square-top-text">
              {/*   <span className="arrow" style={{ borderRightColor: 'rgb(0, 133, 255)' }}></span> */}
                <span className="extra-text" style={{ backgroundColor: 'rgb(0, 133, 255)' }}>
                  Most <br/> Popular
                </span>
              </span>

            )}
          </h2>

          <div className="pricing-section">
            <div className="price-card-price-per-month" style={{ color: 'rgb(51, 51, 51)' }}>
              {!offerPrice ? (
                <span className="plan-price" style={{ color: 'rgb(162, 93, 220)' }}>
                  ${price?.toFixed(0)}
                </span>
              ) : (
                <span style={{ color: 'rgb(162, 93, 220)' }}>
                  <span className="plan-price LY_OfferStrikeThrough"
                  >${price?.toFixed(0)}</span>
                </span>
              )}
              <span className=""> / {priceTag}</span>

            </div>

            {offerPrice &&

              <div className="price-card-price-per-month" style={{ color: 'rgb(51, 51, 51)' }}>

                <span className="plan-price" style={{ color: 'red' }}>
                  ${offerPrice}
                </span>

                <div className='LY_PriceTag'>

                  <span style={{ color: 'red' }}>
                    /{priceTag} 
                  </span>

                  <div style={{ color: 'red' }}>
                    {discountTag} ** SPECIAL OFFER **
                  </div>

                </div>


              </div>

            }



          </div>

          <div style={{ marginBottom: '20px', marginTop: '20px', width: '100%' }}>

            {plan.isCurrentPlan && (
              <b>**Current Plan**</b>


            )}
            {!plan.isCurrentPlan && (

              <MT.Button

                className="LY_SignUpButton"
                variant="filled" size="md" radius="xl"
                onClick={() => onSubscribeClick(plan)}
                loading={vm.isActionInProgress} loaderProps={{ type: 'dots' }}
              >

                {/*   Subscribe */}
                {buttonLabel}

              </MT.Button>


              

            )}
            {/*      <a href={`${plan.buttonUrl}`} className="btn-danger btn-sm btn-purple btn-text-sm">
                   {plan.buttonLabel}  

                 
              </a> */}
          </div>


          <div className="tier-description"
            dangerouslySetInnerHTML={{ __html: description! }}
          >

          </div>


        </div>

        <div className="separator"  ></div>

        <div className="tier-includes-container">
          <div className="tier-includes-list">
            {plan.features?.map((feature, featureIndex) => {
              let value = feature.value;
              if (value === '-1') {
                value = 'Unlimited -';
              }

              let description = feature?.billingPlanFeatureType?.description || '';
              if (feature?.valueDescription) {
                description = LIB.StringHelper.replaceNewLinesWithBR(feature.valueDescription);
              }



              return (
                <div className="LY_PricingFeatureLine" key={featureIndex}>


                  {feature?.billingPlanFeatureTypeId !== App.BillingPlanFeatureTypeEnum.Info && (
                    <>
                      <span >
                        {LIB.ConvertHelper.isBool(value) ? (
                          <Icons.IconCheck style={{ color: 'lawngreen' }} />
                        ) : (
                          value
                        )}
                      </span>
                      <span>{feature.billingPlanFeatureType?.label}</span>

                      <MT.Tooltip  withArrow  position="right"  
                        label={`${description || ''}`}
                      >
                        <Icons.IconInfoCircle style={{ color: 'gray' }} />
                      </MT.Tooltip>

                    </>
                  )}
                  {feature?.billingPlanFeatureTypeId === App.BillingPlanFeatureTypeEnum.Info && (
                    <>
                      <span>{value}</span>
                      <span dangerouslySetInnerHTML={{ __html: description! }} />



                      {/*  {feature?.valueDescription && (

                          <Tooltip arrow placement="right"
                            title={`${feature?.valueDescription}`}
                          >
                            <Icons.Info style={{ color: 'gray' }} />
                          </Tooltip>


                        )} */}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>



    </div>;


  }


  function render() {

    console.log('BillingPricingTable render');

    var company = currentUser?.company;


    var theme = App.AppBase.theme;


    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };

    var plans = billingPlans;

    if (LIB.Common.isNullOrEmpty(plans) || plans.length < 1)
      return null;

    console.log('BillingPricingTable plans', plans);

    var header: any = '';

    if (company?.hasActiveBillingPlan) {
      header = <h3>Billing Plans:</h3>;

    }

    let count = 0;

    return (

      <div style={{ marginBottom: '50px' }}>
        {header}
        <div className="LY_PricingGridContainer" >

          {getOfferInfoBox()}
          <div
            className={`LY_PricingGrid ${props.pricingGridClassName || ''}`}

          >
            {
              plans?.map((plan, index) => {

                const priorPlan = index > 0 ? plans[ index - 1 ] : plan;

                var item = getPlanBox(plan, priorPlan, index);

                return item;
              })}
          </div>

        </div>
      </div>
    );


  }


  return render();


});
